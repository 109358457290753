<template>
  <div class="container">
    <h1 class="mb-2">{{ details.title }}</h1>
    <div class="row">
      <div class="col-md-8">
        <div class="course-video" v-if="!loading">
          <vimeo-player
            v-if="!current.mega"
            ref="player"
            :video-url="current.video_url"
            player-width="auto"
            player-height="auto"
            :options="{ responsive: true }"
          />
          <mega-player :src="current.video_url" v-if="current.mega" />
        </div>
        <h2 class="mt-3">{{ current.title }}</h2>
        <div class="card" v-if="!loading">
          <div class="px-2 py-3">
            <video-comments
              :about="current.about"
              type="academy"
              :id="current.id"
              role="moderator"
              v-if="!loading"
            />
          </div>
        </div>
      </div>
      <div class="col-md-4" v-if="! loading">
        <div class="videos-list">
          <div class="video" v-for="(video, i) in videos" :key="`video-${i}`">
            <div class="thumb">
              <router-link :to="{ params: { video_id: video.id }}">
                <video-thumbnail :video="video" />
              </router-link>
            </div>
            <div class="title">
              <router-link
                :to="{ params: { video_id: video.id }}"
                class="text-black"
              >
                <p :class="loading ? 'skeleton skeleton-text' : ''">
                  {{ video.title }}
                </p>
                <template v-if="loading">
                  <p class="skeleton skeleton-text"></p>
                  <p class="skeleton skeleton-text"></p>
                </template>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      videos: [{}, {}, {}, {}, {}],
      current: {},
      details: {},
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.loading = true;
      this.videos = [{}, {}, {}, {}, {}];

      this.$axios
        .get(`/api/v1/moderator/academy/${this.$route.params.week_id}/videos/${this.$route.params.video_id}`)
        .then((response) => {
          this.videos = response.data.videos;
          this.current = response.data.current;
          this.details = response.data.details;
          this.loading = false;
        });
    },
  },

  computed: {
    video_id() {
      return this.$route.params.video_id;
    },
  },

  watch: {
    video_id() {
      this.fetch();
    },
  },
};
</script>

<style lang="scss" scoped>
.course-video {
  min-height: 400px;
  width: 100%;
  background: #000;
}

.videos-list {
  .video {
    display: flex;
    background: #fff;
    margin-bottom: 10px;

    .thumb {
      width: 40%;

      img {
        width: 100%;
      }
    }

    .title {
      width: 60%;
      padding: 0 5px;
      font-weight: bold;
    }
  }
}
</style>
